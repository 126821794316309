export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDcAquwdgHCtC-DUQ_-fMa0tdqUBKVi5C8',
    authDomain: 'planningpoker.live',
    databaseURL: 'https://card-estimator.firebaseio.com',
    projectId: 'card-estimator',
    storageBucket: 'card-estimator.appspot.com',
    messagingSenderId: '417578634660',
    appId: '1:417578634660:web:3617c13e4d28109aa18531',
    measurementId: 'G-ZVXZ9HTLGS',
  },
  recaptcha3SiteKey: '6Lf5e-weAAAAAIkZ1MWmXR9xxHkv2jPQda325CRm',
  useEmulators: false,
  premiumPriceId: 'price_1MkYV6CG1hllVHncXLgIFXLK',
  premiumPriceIdUsd: 'price_1Ol6WDCG1hllVHnchXIcnKRk',
  powerUserPromoCode: 'promo_1O436TCG1hllVHncuVzCCRoD',
  smallBundlePriceId: 'price_1OMHDkCG1hllVHncXUhbbxLw',
  smallBundlePriceIdUsd: 'price_1Ol6PcCG1hllVHnc1NcrFD40',
  largeBundlePriceId: 'price_1OMHEACG1hllVHncfpLi6Azq',
  largeBundlePriceIdUsd: 'price_1Ol6UUCG1hllVHncNq1yCyDe',
  megaBundlePriceId: 'price_1OMHErCG1hllVHncExHhvwEg',
  megaBundlePriceIdUsd: 'price_1Ol6UtCG1hllVHncjHx0qQF4',
  orgBundlePriceId: 'price_1PmhwHCG1hllVHnc1xKWi8bD',
  orgBundlePriceIdUsd: 'price_1PmhwHCG1hllVHncF0Ot6HKr',
  cloudProjectNumber: '417578634660',
};
